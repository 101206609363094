import { createRoot } from 'react-dom/client';
import App from './App.jsx';
import 'react-day-picker/style.css';
import packageJson from '../package.json';

import { SmartRetailTheme } from '@group-link-one/grouplink-components';
import { env } from './env.js';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { queryClient } from './Services/queryClient.js';
import { BrowserRouter } from 'react-router-dom';
import { initializeProducts } from './Context/FBProvider.jsx';

import 'react-day-picker/style.css';
import { SentryProvider, AuthProvider } from '@group-link-one/gl-utils';

initializeProducts();

createRoot(document.getElementById('root')!).render(
  <SentryProvider
    config={{
      environment: env.VITE_SENTRY_ENVIRONMENT,
      dsn: env.VITE_SENTRY_DSN,
      appVersion: packageJson.version,
    }}
  >
    <QueryClientProvider client={queryClient}>
      <AuthProvider
        baseURL={env.VITE_REACT_APP_API_URL}
        keyStorage={`@utilities-${env.VITE_NODE_ENV}`}
      >
        <BrowserRouter>
          <SmartRetailTheme />
          <App />
        </BrowserRouter>

        <ReactQueryDevtools />
      </AuthProvider>
    </QueryClientProvider>
  </SentryProvider>,
);
