import {
  MeasurementCategory,
  useAuth,
  UserDeviceGroup,
} from '@group-link-one/gl-utils';
import {
  BigNumberProps,
  Colors,
  DeviceListStoreState,
  GL_CHECK,
  GL_COLORS,
  GL_GAS,
  GL_GLStation,
  GL_IDEA,
  GL_INTERNET,
  GL_LOGIN,
  GL_THUNDERBOLT,
  GL_WATER,
  GLSelectMultiple,
  GLSelectMultipleOptions,
  i18n,
  useDeviceListStore as useGLDeviceListStore,
  useGLPagination,
  useHandleFilterDeviceGroup,
  useI18n,
} from '@group-link-one/grouplink-components';
import { useQuery } from '@tanstack/react-query';
import { useEffect, useMemo, useState } from 'react';
import { useMediaQuery } from 'usehooks-ts';

import CheckImg from '../../images/DeviceList/icon-check.svg';
import InternetImg from '../../images/DeviceList/icon-internet.svg';
import LoginImg from '../../images/DeviceList/icon-log-in.svg';
import StationImg from '../../images/DeviceList/icon-station.svg';
import { useDeviceListService } from '../../Services/deviceListService/useDeviceListService';
import { BignumbersResponse } from '../../Services/deviceListService/useDeviceListService.types';
import { useDetailsDeviceStore } from './store/details-device-store';
import {
  DeviceListStoreActions,
  DeviceListStoreState as DeviceListStoreStateLocal,
  useDeviceListStore,
} from './store/device-list-store';
import { useTagsService } from '@/Services/tagsService/useTagsService';
import { Features, usePrivileges } from '@/hooks/usePrivilleges';
import { useGLFlags } from '@group-link-one/gl-utils';

export const useCasesMap = [
  {
    id: 'gas',
    text: i18n.t('deviceList.selectUseCase.gas'),
    icon: <GL_GAS size={14} fill={Colors.ORANGE} />,
  },
  {
    id: 'water',
    text: i18n.t('deviceList.selectUseCase.water'),
    icon: <GL_WATER size={14} fill={GL_COLORS.ACCENT_COLOR} />,
  },
  {
    id: 'energy',
    text: i18n.t('deviceList.selectUseCase.energy'),
    icon: <GL_THUNDERBOLT size={14} fill={Colors.PURPLE} />,
  },
  {
    id: 'light',
    // text: i18n.t("deviceList.selectUseCase.energy"),
    text: 'Street Light',
    icon: <GL_IDEA size={14} fill="#00DCFF" />,
  },
];

interface IUserDeviceListBody {
  deviceListStateGL: DeviceListStoreState;
  deviceListState: DeviceListStoreStateLocal;
  deviceListActions: DeviceListStoreActions;
  userGroupsFormatted: GLSelectMultipleOptions[] | undefined;
  bigNumbers: BigNumberProps[];
  features: Features;
  requests: {
    lastReading: boolean;
    getBignumberCountsDeviceList: boolean;
  };
  isOpen: boolean;
  tagOptionsFormatted: GLSelectMultipleOptions[] | undefined;
  tagValuesFormatted: GLSelectMultipleOptions[] | undefined;
  groupsChildrensAvailables: GLSelectMultipleOptions[] | undefined;
  showFeature: (feature: string) => boolean;
  onSelectUseCase: (useCaseOptionsSelecteds: GLSelectMultipleOptions[]) => void;
  onSelectUserGroup: (
    userGroupsSelecteds: GLSelectMultipleOptions[],
    type?: 'parent' | 'child',
  ) => void;
  onSelectTag: (tagSelecteds: GLSelectMultipleOptions[]) => void;
  onSelectTagValue: (tagValueSelecteds: GLSelectMultipleOptions[]) => void;
}

export const useDeviceListBody = (): IUserDeviceListBody => {
  const [infoCounts, setInfoCounts] = useState({
    activated: 0,
    pending: 0,
    decommissioned: 0,
    totalDevices: 0,
  });

  const [groupsChildrensAvailables, setGroupsChildrensAvailables] = useState<
    GLSelectMultipleOptions[] | undefined
  >(undefined);

  const isSM = useMediaQuery('(max-width: 660px)');
  const { getBignumbers } = useDeviceListService();
  const { user, userGroups } = useAuth();
  const { getTags, getTagValue } = useTagsService();
  const { t } = useI18n();
  const { state } = useDetailsDeviceStore();
  //const { showFeature } = useGLFlags();
  const { requests, features } = usePrivileges();
  const { showFeature } = useGLFlags();

  const { state: deviceListState, actions: deviceListActions } =
    useDeviceListStore();

  const { state: deviceListStateGL, actions: deviceListActionsGL } =
    useGLDeviceListStore();

  const { formatGroupList, onSelectUserGroup: onHandleSelectUserGroup } =
    useHandleFilterDeviceGroup();

  const { state: paginationState } = useGLPagination();

  const { data: bignumbersResponse, isLoading: bigNumbersIsLoading } = useQuery(
    {
      queryKey: ['bignumbers-device-list'],
      queryFn: async () => {
        const response = await getBignumbers({
          org_id: user!.org.id!,
        });

        return response.rows;
      },
      enabled: requests.getBignumberCountsDeviceList,
    },
  );

  const { data: tags } = useQuery({
    queryKey: ['get-tags'],
    queryFn: async () => {
      if (
        !showFeature('show_device_tags_page') &&
        !showFeature('show_tags_device_list')
      )
        return;
      const response = await getTags({
        key: undefined,
      });

      return response.rows;
    },
  });

  const { data: tagValues } = useQuery({
    queryKey: ['get-tags-values', deviceListStateGL.tagSelecteds],
    queryFn: async () => {
      if (!deviceListStateGL.tagSelecteds) return;

      const response = await getTagValue({
        key: deviceListStateGL.tagSelecteds[0].id,
      });

      return response.values;
    },
  });

  const bigNumbers: BigNumberProps[] = useMemo(() => {
    const all: BigNumberProps[] = [
      {
        iconLeft: (
          <GL_GLStation
            fillOne={GL_COLORS.ULTRADARK_GREY_SECONDARY}
            fill={GL_COLORS.ULTRADARK_GREY_SECONDARY}
            size={24}
          />
        ),
        iconRight: <img src={StationImg} alt="" />,
        iconRightStyle: {
          bottom: -20,
          right: isSM ? -40 : 20,
          transform: isSM ? 'scale(0.85)' : 'none',
        },
        title: t('deviceList.bigNumbers.one.title'),
        count: infoCounts.activated,
        type: isSM ? 'sm' : 'lg',
        backgroundColor: Colors.LIGHT_GREEN,
        textColor: 'ULTRADARK_GREY_SECONDARY',
        tooltipText: t('deviceList.bigNumbers.one.tooltipDescription'),
        tooltipPosition: 'top-center',
        tooltipZIndex: 1,
        tooltipTextStyle: {
          whiteSpace: 'nowrap',
        },
        isLoading: bigNumbersIsLoading,
        isToAppear: features.deviceList.bigNumbers.activatedDevices,
      },
      {
        iconLeft: <GL_LOGIN fill={GL_COLORS.WHITE} size={24} />,
        iconRight: <img src={LoginImg} alt="" />,
        iconRightStyle: {
          bottom: -20,
          right: isSM ? -40 : 20,
          transform: isSM ? 'scale(0.85)' : 'none',
        },
        title: t('deviceList.bigNumbers.two.title'),
        count: infoCounts.pending,
        type: isSM ? 'sm' : 'lg',
        backgroundColor: Colors.NAVY,
        textColor: 'WHITE',
        tooltipText: t('deviceList.bigNumbers.two.tooltipDescription'),
        helpIconColor: 'WHITE',
        tooltipZIndex: 1,
        tooltipTextStyle: {
          whiteSpace: 'nowrap',
        },
        isLoading: bigNumbersIsLoading,
        isToAppear: features.deviceList.bigNumbers.pendingDevices,
      },
      {
        iconLeft: <GL_CHECK fill={GL_COLORS.WHITE} size={24} />,
        iconRight: <img src={CheckImg} alt="" />,
        iconRightStyle: {
          bottom: 0,
          right: isSM ? -40 : 20,
          transform: isSM ? 'scale(0.85)' : 'none',
        },
        title: t('deviceList.bigNumbers.three.title'),
        count: infoCounts.decommissioned,
        type: isSM ? 'sm' : 'lg',

        backgroundColor: GL_COLORS.ACCENT_COLOR,
        textColor: 'WHITE',
        tooltipText: t('deviceList.bigNumbers.three.tooltipDescription'),
        helpIconColor: 'WHITE',
        tooltipZIndex: 1,
        tooltipTextStyle: {
          whiteSpace: 'nowrap',
        },
        isLoading: bigNumbersIsLoading,
        isToAppear: features.deviceList.bigNumbers.deactivatedDevices,
      },
      {
        iconLeft: <GL_INTERNET fill={GL_COLORS.WHITE} size={24} />,
        iconRight: <img src={InternetImg} alt="" />,
        iconRightStyle: {
          bottom: 0,
          right: isSM ? -40 : 20,
          transform: isSM ? 'scale(0.85)' : 'none',
        },
        title: t('deviceList.bigNumbers.four.title'),
        count: infoCounts.totalDevices,
        type: isSM ? 'sm' : 'lg',
        backgroundColor: Colors.PURPLE,
        textColor: 'WHITE',
        tooltipText: t('deviceList.bigNumbers.four.tooltipDescription'),
        helpIconColor: 'WHITE',
        tooltipZIndex: 1,
        tooltipTextStyle: {
          whiteSpace: 'nowrap',
        },
        isLoading: bigNumbersIsLoading,
        isToAppear: features.deviceList.bigNumbers.totalDevices,
      },
    ];

    return all.filter((item) => item.isToAppear);
  }, [infoCounts, isSM, bigNumbersIsLoading, features]);

  const userGroupsFormatted: GLSelectMultipleOptions[] | undefined =
    useMemo(() => {
      return formatGroupList(userGroups);
    }, [userGroups]);

  const tagOptionsFormatted: GLSelectMultipleOptions[] | undefined =
    useMemo(() => {
      return (
        tags &&
        (tags.map((tag) => ({
          id: String(tag.key),
          text: tag.label,
        })) as GLSelectMultipleOptions[])
      );
    }, [tags]);

  const tagValuesFormatted: GLSelectMultipleOptions[] | undefined =
    useMemo(() => {
      return (
        tagValues &&
        (tagValues.map((tagValue) => ({
          id: String(tagValue.id),
          text: tagValue.value,
        })) as GLSelectMultipleOptions[])
      );
    }, [tagValues]);

  function onSelectUseCase(
    useCaseOptionsSelecteds: GLSelectMultipleOptions[],
  ): void {
    deviceListActionsGL.setUseCaseOptionsSelecteds(useCaseOptionsSelecteds);
  }

  function onSelectUserGroup(
    userGroupsSelecteds: GLSelectMultipleOptions[],
    type?: 'parent' | 'child',
  ): void {
    onHandleSelectUserGroup({
      userGroupsSelecteds,
      type,
      setUserParentGroupsSelecteds:
        deviceListActionsGL.setUserParentGroupsSelecteds,
      setUserGroupSelecteds: deviceListActionsGL.setUserGroupSelecteds,
      setGroupsChildrensAvailables,
    });
  }

  function onSelectTag(tagSelecteds: GLSelectMultipleOptions[]): void {
    deviceListActionsGL.setTagSelecteds(tagSelecteds);
  }

  function onSelectTagValue(
    tagValueSelecteds: GLSelectMultipleOptions[],
  ): void {
    deviceListActionsGL.setTagValueSelected(tagValueSelecteds);
  }

  useEffect(() => {
    if (bignumbersResponse) {
      const activated = parseInt(
        (
          bignumbersResponse.find(
            (item: BignumbersResponse) => item.type === 'activated_devices',
          )?.count || '0'
        ).toString(),
        10,
      );
      const pending = parseInt(
        (
          bignumbersResponse.find(
            (item: BignumbersResponse) => item.type === 'pending_devices',
          )?.count || '0'
        ).toString(),
        10,
      );
      const decommissioned = parseInt(
        (
          bignumbersResponse.find(
            (item: BignumbersResponse) =>
              item.type === 'decommissioned_devices',
          )?.count || '0'
        ).toString(),
        10,
      );

      const totalDevices = activated + pending + decommissioned;

      setInfoCounts({
        activated,
        pending,
        decommissioned,
        totalDevices,
      });
    }
  }, [bignumbersResponse]);

  useEffect(() => {
    deviceListActions.resetAll();

    const useCaseOptionsAvailables = useCasesMap.filter((useCase) =>
      user?.device_measurement_categories?.includes(
        useCase.id as MeasurementCategory,
      ),
    );

    if (
      useCaseOptionsAvailables.length > 1 &&
      paginationState.activeTabId === 1
    ) {
      deviceListActionsGL.setUseCaseOptions(useCaseOptionsAvailables);
    } else {
      deviceListActionsGL.setUseCaseOptionsSelecteds(undefined);
    }
  }, [user, paginationState.activeTabId]);

  return {
    requests,
    features,
    showFeature,
    groupsChildrensAvailables,
    deviceListStateGL,
    deviceListState,
    deviceListActions,
    userGroupsFormatted,
    bigNumbers,
    isOpen: state.modalIsOpen,
    tagOptionsFormatted,
    tagValuesFormatted,
    onSelectUseCase,
    onSelectUserGroup,
    onSelectTag,
    onSelectTagValue,
  };
};
