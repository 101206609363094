import { useI18n } from '@group-link-one/grouplink-components';
import { DateTime } from 'luxon';
import { useMediaQuery } from 'usehooks-ts';
import { IUseEventListCard } from './EventListCards.types';

export const useEventListCard = (): IUseEventListCard => {
  const isMobile = useMediaQuery('(max-width: 1024px)');
  const { t } = useI18n();

  function formatTime(timestamp: number): string {
    const dt = DateTime.fromMillis(timestamp, { zone: 'utc' });

    if (dt.hour > 0) {
      return dt.toFormat("HH'h 'mm'm'");
    }

    if (dt.minute > 0) {
      return dt.toFormat("mm'm 's's'");
    }

    if (dt.second > 0) {
      return dt.toFormat("s's");
    }

    if (dt.millisecond > 0) {
      return dt.toFormat('SSS') + 'ms';
    }

    return '--';
  }

  function formatLiters(milliliters: number): string {
    if (milliliters < 1000) {
      return `${milliliters.toFixed(2)} ml`;
    }

    const liters = Math.floor(milliliters / 1000);
    const remainingMilliliters = milliliters % 1000;

    if (remainingMilliliters === 0) {
      return `${liters}L`;
    } else {
      return `${liters}.${remainingMilliliters.toFixed(0)} L`;
    }
  }

  function formatCubicMeters(milliliters: number): string {
    // const cubicMeters = milliliters / 1_000_000;
    // return `${cubicMeters.toFixed(6)} m³`;

    const cubicMeters = Number(milliliters);
    return `${cubicMeters.toFixed(3)} m³`;
  }

  function formatEnergyValue(value: string): string {
    const trimmedValue = value.replace(/^0+/, '');
    return trimmedValue ? trimmedValue + ' KWh' : '0 KWh';
  }

  function formatAlarms(alarms: number): string {
    if (alarms === 0) {
      return '--';
    }

    if (alarms === 1) {
      return alarms + ` ${t('eventList.columns.alarm')}`;
    }

    return alarms + ` ${t('eventList.columns.alarms')}`;
  }

  return {
    t,
    formatTime,
    formatCubicMeters,
    formatLiters,
    formatEnergyValue,
    formatAlarms,
    isMobile,
  };
};
