import { useAuth } from '@group-link-one/gl-utils';
import {
  AlertType,
  GLDateRangeType,
  useActiveLanguageDateFns,
  // useGLPagination,
  useI18n,
  useToast,
} from '@group-link-one/grouplink-components';
import { QueryKey, useQueryClient } from '@tanstack/react-query';
import { useMemo, useRef, useState } from 'react';
import { useMediaQuery } from 'usehooks-ts';

import { onRequestError } from '../../../../Errors/onError';
import { useDeviceListService } from '../../../../Services/deviceListService/useDeviceListService';
import {
  DevicesActivatedLastReadingsChannel,
  GetDevicesActivatedLastReadingsResponse,
} from '../../../../Services/deviceListService/useDeviceListService.types';
import { UseCase } from '../../../EventList/Content/Columns/AllColumns';
import {
  DetailsDeviceStoreActions,
  DetailsDeviceStoreState,
  useDetailsDeviceStore,
} from '../../store/details-device-store';
import { getOptionsByApplication } from '../../utils/getOptionsByApplication';
import { useFBAnalytics } from '@/Context/FBAnalytics/FBAnalyticsProvider';
import { FBAnalyticsEvent } from '@/Context/FBAnalytics/types/FBAnalyticsEvent.types';
import { format, Locale } from 'date-fns';
import { Features, usePrivileges } from '@/hooks/usePrivilleges';
import { useExtractURLSearch } from '@/hooks/useExtractURLSearch';

export const alertsMap = {
  CALIBRATING: 'Calibrating',
  CAR_PLUGGED: 'Car plugged',
  LEAKAGE: 'Leakage',
  MAGNETIC_FRAUDULENCE: 'Magnetic fraud',
  MAX_FLOW: 'Max flow',
  OPEN_DOOR: 'Open door',
  OUT_OF_RANGE: 'Out of range',
  REVERSE_FLOW: 'Reverse flow',
  VIOLATION: 'Violation',
  WIRE_CUTTING: 'Wire cutting',
  LIGHT_FLICKERING_LAMP: 'Flickering lamp',
  LIGHT_OPEN_LOAD: 'Open load',
  LIGHT_OUTPUT_STATUS: 'Output status',
  LIGHT_OVER_CURRENT: 'Over current',
  LIGHT_OVER_VOLTAGE: 'Over voltage',
  LIGHT_PHOTOCELL_STATUS: 'Photocell status',
  LIGHT_UNDER_VOLTAGE: 'Under voltage',
} as Record<AlertType, string>;

interface IUseDeviceDetailsTitleContent {
  device: GetDevicesActivatedLastReadingsResponse | undefined;
  range: GLDateRangeType;
  userUseCase: UseCase;
  currentChannelValue: string;
  currentChannelData: DevicesActivatedLastReadingsChannel | undefined;
  detailsDeviceState: DetailsDeviceStoreState;
  detailsDeviceActions: DetailsDeviceStoreActions;
  features: Features;
  t: (key: string) => string;
  streetLightValues: {
    rms_voltage: string;
    rms_current: string;
  };
  hasAlarm: (alarm: AlertType) => boolean | undefined;
  langActive: Locale;
  alertsMap: Record<AlertType, string>;
  editInputRef: React.RefObject<HTMLInputElement>;
  remoteIDInputRef: React.RefObject<HTMLInputElement>;
  hasMoreThanOneChannel: boolean;
  hasToShowTagsInfo: boolean;
  hasAlarms: boolean;
  isMobile: boolean;
  is1190px: boolean;
  is768Px: boolean;
  editingDeviceNameValue: string | undefined;
  editingRemoteIdValue: string | undefined;
  isEditingDeviceName: boolean;
  isEditingRemoteID: boolean;
  setEditingDeviceNameValue: React.Dispatch<
    React.SetStateAction<string | undefined>
  >;
  setIsEditingDeviceName: React.Dispatch<React.SetStateAction<boolean>>;
  setisEditingRemoteID: React.Dispatch<React.SetStateAction<boolean>>;
  setEditingRemoteIdValue: React.Dispatch<
    React.SetStateAction<string | undefined>
  >;
  onDeviceNameClick: (type: 'device_name' | 'remote_id') => void;
  onDeviceNameBlur: (
    event: React.FocusEvent<HTMLInputElement>,
  ) => Promise<void>;
  onRemoteIDBlur: (event: React.FocusEvent<HTMLInputElement>) => Promise<void>;
  onAddressClick: () => void;
  onDateChange: (range: GLDateRangeType) => void;
  onChannelChange: (channel?: string) => void;
  formatActivationPlatform: () => string;
  logEventAnalytics: (event: FBAnalyticsEvent) => void;
  setURLParams: (newParams: Record<string, string>) => void
  formatDate: (date?: number) => {
    day: string;
    hour: string;
  }
}

export const useDeviceDetailsTitleContent =
  (): IUseDeviceDetailsTitleContent => {
    const { logEventAnalytics } = useFBAnalytics();
    const [isEditingDeviceName, setIsEditingDeviceName] = useState(false);
    const [isEditingRemoteID, setisEditingRemoteID] = useState(false);

    const { setURLParams } = useExtractURLSearch();

    const { state: detailsDeviceState, actions: detailsDeviceActions } =
      useDetailsDeviceStore();

    const { updateDevice } = useDeviceListService();

    const { user } = useAuth();
    const { t } = useI18n();

    const { addToast } = useToast();

    const editInputRef = useRef<HTMLInputElement>(null);
    const remoteIDInputRef = useRef<HTMLInputElement>(null);

    const isMobile = useMediaQuery('(max-width: 1270px)');
    const langActive = useActiveLanguageDateFns();

    const is1190px = useMediaQuery('(max-width: 1190px)');
    const is768Px = useMediaQuery('(max-width: 768px)');

    const userUseCase = user?.use_case as UseCase;

    const queryClient = useQueryClient();
    const { features } = usePrivileges();

    function updateTabOne(
      newChannelData: DevicesActivatedLastReadingsChannel[],
    ): void {
      const allCachesData: [
        QueryKey,
        GetDevicesActivatedLastReadingsResponse[] | undefined,
      ][] = queryClient.getQueriesData({
        queryKey: ['devices-activated-last-readings'],
      });

      if (allCachesData.length === 0) {
        return;
      }

      allCachesData.forEach(([queryKey, cachedData]) => {
        if (!cachedData) {
          return;
        }

        const hasDeviceID = cachedData.some(
          (device) => device.device_id === detailsDeviceState.device?.device_id,
        );

        if (!hasDeviceID) {
          return;
        }

        const newCacheData = cachedData.map((device) => {
          if (device.device_id === detailsDeviceState.device?.device_id) {
            return {
              ...device,
              channels: newChannelData,
            };
          }

          return device;
        });

        queryClient.setQueryData(queryKey, newCacheData);
      });
    }

    // const { state: paginationState, actions: paginationActions } =
    //   useGLPagination();

    const currentChannelValue = useMemo(() => {
      const channelsMap = {
        channel_0: 'Channel 1',
        channel_1: 'Channel 2',
      };

      return channelsMap[
        `channel_${detailsDeviceState.channelActive || 0}` as keyof typeof channelsMap
      ];
    }, [detailsDeviceState.device?.channels, detailsDeviceState.channelActive]);

    const currentChannelData = useMemo(() => {
      return detailsDeviceState.device?.channels[
        detailsDeviceState.channelActive || 0
      ];
    }, [detailsDeviceState.channelActive]);

    const [editingDeviceNameValue, setEditingDeviceNameValue] = useState<
      string | undefined
    >(currentChannelData?.name);

    const [editingRemoteIdValue, setEditingRemoteIdValue] = useState<
      string | undefined
    >(currentChannelData?.remote_id);

    const streetLightValues = useMemo(() => {
      const rmsVoltage =
        currentChannelData?.last_reading &&
        typeof currentChannelData?.last_reading !== 'number' &&
        currentChannelData?.last_reading.series.find(
          (series) => series.serie === 'rms_voltage',
        );

      const rmsCurrent =
        currentChannelData?.last_reading &&
        typeof currentChannelData?.last_reading !== 'number' &&
        currentChannelData?.last_reading.series.find(
          (series) => series.serie === 'rms_current',
        );

      const application = getOptionsByApplication({
        type: 'GLUtilitiesLight',
      });

      return {
        rms_voltage: rmsVoltage
          ? rmsVoltage.value + ` ${application.unit}`
          : '0',
        rms_current: rmsCurrent
          ? rmsCurrent.value + ` ${application.unit}`
          : '0',
      };
    }, [currentChannelData]);

    const hasMoreThanOneChannel = !!detailsDeviceState?.device?.channels && detailsDeviceState.device.channels.length > 1;
    const hasToShowTagsInfo = features.deviceList.detailsModal.showTags || features.deviceList.detailsModal.createTag
    const hasAlarms = !!detailsDeviceState.device && !!currentChannelData && currentChannelData.alerts.length > 0
    // const activeTab = useMemo(() => {
    //   return paginationActions.getActiveTabById(paginationState.activeTabId);
    // }, [paginationState.activeTabId]);

    function onAddressClick(): void {
      if (!detailsDeviceState.device) {
        return;
      }

      const lat = detailsDeviceState.device.meta.latitude;
      const lng = detailsDeviceState.device.meta.longitude;

      const googleMapsUrl = `https://www.google.com/maps/search/?api=1&query=${lat},${lng}`;
      window.open(googleMapsUrl, '_blank');
    }

    function onDeviceNameClick(type: 'device_name' | 'remote_id'): void {
      if (type === 'device_name') {
        setIsEditingDeviceName(true);
        setTimeout(() => {
          editInputRef.current?.focus();
        }, 100);

        return;
      }

      if (type === 'remote_id') {
        setisEditingRemoteID(true);
        setTimeout(() => {
          remoteIDInputRef.current?.focus();
        }, 100);

        return;
      }
    }

    function updateStoreAfterSuccess(
      newChannelData: DevicesActivatedLastReadingsChannel[],
    ): void {
      if (!currentChannelData || !detailsDeviceState.device) {
        return;
      }

      detailsDeviceActions.setDevice({
        ...detailsDeviceState.device,
        channels: newChannelData,
      });
    }

    function updateCacheAfterSuccess(
      newChannelData: DevicesActivatedLastReadingsChannel[],
    ): void {
      // if (!currentChannelData || !detailsDeviceState.device || !activeTab) return;
      if (!currentChannelData || !detailsDeviceState.device) {
        return;
      }

      // const activeTabMap = {
      //   "tab-1": {
      //     do: () => updateTabOne(newChannelData),
      //   },
      // };

      // activeTabMap[`tab-${activeTab.id}` as keyof typeof activeTabMap]?.do();

      updateTabOne(newChannelData);
    }

    async function onDeviceNameBlur(
      event: React.FocusEvent<HTMLInputElement>,
    ): Promise<void> {
      setIsEditingDeviceName(false);

      if (!detailsDeviceState.device?.device_id) {
        return;
      }

      if (event.target.value === currentChannelData?.name) {
        setEditingDeviceNameValue(currentChannelData?.name);
        return;
      }

      if (!event.target.value) {
        setEditingDeviceNameValue(currentChannelData?.name);
        return;
      }

      setEditingDeviceNameValue(event.target.value);

      try {
        await updateDevice({
          device_id: detailsDeviceState.device?.device_id,
          channel_data: detailsDeviceState.device?.channels.map((channel) => ({
            channel_name:
              currentChannelData?.channel === channel.channel
                ? event.target.value
                : channel.name,
            channel: channel.channel,
          })),
        });

        const newChannelData: DevicesActivatedLastReadingsChannel[] =
          detailsDeviceState.device?.channels.map((channel) => ({
            ...channel,
            name: event.target.value,
          }));

        addToast({
          title: t('deviceList.detailsModal.onEditDeviceName.success.title'),
          message: t(
            'deviceList.detailsModal.onEditDeviceName.success.description',
          ),
        });

        updateStoreAfterSuccess(newChannelData);
        updateCacheAfterSuccess(newChannelData);
      } catch (error) {
        onRequestError(error);
        setEditingDeviceNameValue(currentChannelData?.name);

        addToast({
          type: 'error',
          title: t('deviceList.detailsModal.onEditDeviceName.error.title'),
          message: t(
            'deviceList.detailsModal.onEditDeviceName.error.description',
          ),
        });
      }
    }

    async function onRemoteIDBlur(
      event: React.FocusEvent<HTMLInputElement>,
    ): Promise<void> {
      setisEditingRemoteID(false);

      if (!detailsDeviceState.device?.device_id) {
        return;
      }

      if (event.target.value === currentChannelData?.remote_id) {
        setEditingRemoteIdValue(currentChannelData?.remote_id);
        return;
      }

      if (!event.target.value) {
        setEditingRemoteIdValue(currentChannelData?.remote_id);
        return;
      }

      setEditingRemoteIdValue(event.target.value);

      try {
        await updateDevice({
          device_id: detailsDeviceState.device?.device_id,
          channel_data: detailsDeviceState.device?.channels.map((channel) => ({
            channel: channel.channel,
            remote_id:
              currentChannelData?.channel === channel.channel
                ? event.target.value
                : channel.remote_id,
            channel_name: channel.name,
          })),
        });

        const newChannelData: DevicesActivatedLastReadingsChannel[] =
          detailsDeviceState.device?.channels.map((channel) => ({
            ...channel,
            remote_id: event.target.value,
          }));

        addToast({
          title: t('deviceList.detailsModal.onEditRemoteID.success.title'),
          message: t(
            'deviceList.detailsModal.onEditRemoteID.success.description',
          ),
        });

        updateStoreAfterSuccess(newChannelData);
        updateCacheAfterSuccess(newChannelData);
      } catch (error) {
        onRequestError(error);
        setEditingRemoteIdValue(currentChannelData?.remote_id);

        addToast({
          type: 'error',
          title: t('deviceList.detailsModal.onEditRemoteID.error.title'),
          message: t(
            'deviceList.detailsModal.onEditRemoteID.error.description',
          ),
        });
      }
    }

    function onDateChange(range: GLDateRangeType): void {
      detailsDeviceActions.setRange(range);
    }

    function onChannelChange(channel?: string): void {
      if (!channel) {
        return;
      }

      const channelClicked = Number(channel.split(' ')[1]) - 1;

      detailsDeviceActions.setChannelActive(channelClicked);

      if (channelClicked === 0) {
        detailsDeviceActions.setCurrentData(
          detailsDeviceState.channelZero || [],
        );
      }

      if (channelClicked === 1) {
        detailsDeviceActions.setCurrentData(
          detailsDeviceState.channelOne || [],
        );
      }
    }

    function hasAlarm(alarm: AlertType): boolean | undefined {
      return detailsDeviceState.device?.channels[0].alerts.includes(alarm);
    }

    function formatActivationPlatform(): string {
      let formattedPlatform = '';

      switch (detailsDeviceState.device?.meta.activation_mode) {
        case 'app':
          formattedPlatform = 'GLActivation';
          break;
        case 'web':
          formattedPlatform = 'Dashboard';
          break;
        case 'server_side':
          formattedPlatform = t('deviceList.detailsModal.viaServer');
          break;
      }

      return formattedPlatform;
    }

    function formatDate(date?: number) {
      return {
        day: date ? format(new Date(date), "dd/MM/yyyy") : '--',
        hour: date ? format(new Date(date), "HH:mm:ss") : '--',
      };
    }

    return {
      device: detailsDeviceState.device,
      range: detailsDeviceState.range,
      userUseCase,
      features,
      currentChannelValue,
      currentChannelData,
      detailsDeviceState,
      detailsDeviceActions,
      t,
      streetLightValues,
      langActive,
      alertsMap,
      editInputRef,
      remoteIDInputRef,
      isMobile,
      is1190px,
      is768Px,
      editingDeviceNameValue,
      editingRemoteIdValue,
      hasAlarms,
      hasMoreThanOneChannel,
      hasToShowTagsInfo,
      isEditingDeviceName,
      isEditingRemoteID,
      hasAlarm,
      setEditingDeviceNameValue,
      setIsEditingDeviceName,
      setisEditingRemoteID,
      setEditingRemoteIdValue,
      onDeviceNameClick,
      onDeviceNameBlur,
      onRemoteIDBlur,
      onAddressClick,
      onDateChange,
      onChannelChange,
      formatActivationPlatform,
      logEventAnalytics,
      setURLParams,
      formatDate
    };
  };
