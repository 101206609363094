import { useGLFlags } from '@group-link-one/gl-utils';
import { useEffect } from 'react';

type Pages = {
  reports: boolean;
  deviceList: boolean;
  deviceGroupList: boolean;
  deviceTags: boolean;
  healthCheck: boolean;
  eventList: boolean;
  settings: {
    overview: boolean;
    myAccount: boolean;
    loginSecurity: boolean;
    users: boolean;
    audit: boolean;
    groupsUsers: boolean;
  };
};

export type Features = {
  impersonation: boolean;
  devices: {
    editDevice: boolean;
    showDetails: boolean;
    activateDevice: boolean;
    decommissionDevice: boolean;
  };
  deviceList: {
    tabs: {
      activatedDevices: boolean;
      withoutReadings: boolean;
      pendingDevices: boolean;
    };
    bigNumbers: {
      activatedDevices: boolean;
      pendingDevices: boolean;
      deactivatedDevices: boolean;
      totalDevices: boolean;
    };
    filters: {
      search: boolean;
      button: boolean;
      byActivationDate: boolean;
      byGroups: boolean;
      byTags: boolean;
      byUseCase: boolean;
    };
    detailsModal: {
      createTag: boolean;
      showTags: boolean;
      showLastEvent: boolean;
      showInfoRemoteId: boolean;
      showInfoOffset: boolean;
      showInfoPulso: boolean;
      showInfoActivatedAt: boolean;
      showInfoActivatedBy: boolean;
      showInfoPlatform: boolean;
      showBigNumberLastReading: boolean;
      showBigNumberDailyConsumption: boolean;
      showBigNumberAverageConsumption: boolean;
      showBigNumberPeakPeriod: boolean;
      showBigNumberActiveEnergy: boolean;
      showBigNumberReactiveEnergy: boolean;
      showBigNumberRmsVoltage: boolean;
      showBigNumberRmsCurrent: boolean;
      showBigNumberReverseEnergy: boolean;
      showGraphDailyConsumption: boolean;
      showGraphConsumptionHistory: boolean;
      showInfoDeviceLocation: boolean;

    };
  };
  deviceGroup: {
    showEdit: boolean;
    showManage: boolean;
    showDelete: boolean;
  };
  healthCheck: {
    bigNumbers: {
      totalDevices: boolean;
      withReadings: boolean;
      withoutReadings: boolean;
    };
    filters: {
      button: boolean;
      byAlert: boolean;
      byGroups: boolean;
      byTags: boolean;
    };
  };
  users: {
    showBtnCreate: boolean;
    showBtnChangeRole: boolean;
    showBtnSetCustomRole: boolean;
    showBtnSetAdminRole: boolean;
    showBtnSetMemberRole: boolean;
    showBtnDeactivatedUser: boolean;
    showFilterSearch: boolean;
  };
  userGroup: {
    showFilterSearch: boolean;
    showBtnCreate: boolean;
    showBtnSetDefault: boolean;
    showBtnEdit: boolean;
  }
};

type Requests = {
  lastReading: boolean;
  getBignumberCountsDeviceList: boolean;
};

interface UsePrivileges {
  pages: Pages;
  features: Features;
  requests: Requests;
}

export const usePrivileges = (): UsePrivileges => {
  const { showFeature } = useGLFlags();

  const pages: Pages = {
    reports: showFeature('show_report_page'),
    deviceList: showFeature('show_device_list_page'),
    deviceGroupList: showFeature('show_devices_group_page'),
    deviceTags: showFeature('show_device_tags_page'),
    healthCheck: showFeature('show_health_check_page'),
    eventList: showFeature('show_network_event_list_page'),
    settings: {
      overview: showFeature('show_settings_overview_page'),
      myAccount: showFeature('show_settings_my_account_page'),
      loginSecurity: showFeature('show_settings_login_security_page'),
      users: showFeature('show_settings_users_page'),
      audit: showFeature('show_settings_audit_page'),
      groupsUsers: showFeature('show_settings_user_group_page'),
    },
  };

  const features: Features = {
    impersonation: showFeature('action_btn_impersonate_mode'),

    devices: {
      editDevice: showFeature('action_btn_show_edit_device'),
      showDetails: showFeature('action_btn_show_device_details'),
      activateDevice: showFeature('action_btn_show_activate_device'),
      decommissionDevice: showFeature('action_btn_show_decommission_device'),
    },

    deviceList: {
      tabs: {
        activatedDevices: showFeature('show_tab_activated_devices'),
        withoutReadings: showFeature('show_tab_without_reading'),
        pendingDevices: showFeature('show_tab_waiting_activation'),
      },
      bigNumbers: {
        activatedDevices: showFeature('show_bignumber_activated_devices'),
        pendingDevices: showFeature('show_bignumber_pending_devices'),
        deactivatedDevices: showFeature('show_bignumber_deacivated_devices'),
        totalDevices: showFeature('show_bignumber_total_devices'),
      },
      filters: {
        search: showFeature('show_btn_search_device_list'),
        button: showFeature('show_btn_filter_device_list'),
        byActivationDate: showFeature('show_filter_by_activation_date'),
        byGroups: showFeature('show_filter_by_device_group_device_list'),
        byTags: showFeature('show_filter_by_tags'),
        byUseCase: showFeature('show_filter_by_use_cases'),
      },
      detailsModal: {
        createTag: showFeature('show_btn_create_tag_modal_details'),
        showTags: showFeature('show_tags_device_list'),
        showLastEvent: showFeature('show_info_last_event_modal_details'),
        showInfoRemoteId: showFeature('show_info_remote_id_modal_details'),
        showInfoOffset: showFeature('show_info_offset_modal_details'),
        showInfoPulso: showFeature('show_info_pulso_modal_details'),
        showInfoActivatedAt: showFeature('show_info_activated_at_modal_details'),
        showInfoActivatedBy: showFeature('show_info_activated_by_modal_details'),
        showInfoPlatform: showFeature('show_info_platform_modal_details'),
        showBigNumberLastReading: showFeature('show_bignumber_last_reading_modal_details'),
        showBigNumberAverageConsumption: showFeature('show_bignumber_average_consumption_modal_details'),
        showBigNumberDailyConsumption: showFeature('show_bignumber_daily_consumption_modal_details'),
        showBigNumberPeakPeriod: showFeature('show_bignumber_peak_period_modal_details'),
        showBigNumberActiveEnergy: showFeature('show_bignumber_active_energy_modal_details'),
        showBigNumberReactiveEnergy: showFeature('show_bignumber_reactive_energy_modal_details'),
        showBigNumberRmsVoltage: showFeature('show_bignumber_rms_voltage_modal_details'),
        showBigNumberRmsCurrent: showFeature('show_bignumber_rms_current_modal_details'),
        showBigNumberReverseEnergy: showFeature('show_bignumber_reverse_energy_modal_details'),
        showGraphDailyConsumption: showFeature('show_graph_daily_consumption_modal_details'),
        showGraphConsumptionHistory: showFeature('show_graph_consumption_history_modal_details'),
        showInfoDeviceLocation: showFeature('show_info_device_location_modal_details'),
      },
    },

    deviceGroup: {
      showEdit: showFeature('show_btn_edit_group'),
      showManage: showFeature('show_btn_manage_devices'),
      showDelete: showFeature('show_btn_delete_group'),
    },

    healthCheck: {
      bigNumbers: {
        totalDevices: showFeature('show_bignumber_total_devices_health_check'),
        withReadings: showFeature('show_bignumber_devices_with_readings'),
        withoutReadings: showFeature('show_bignumber_devices_without_readings'),
      },
      filters: {
        button: showFeature('show_btn_filter_health_check'),
        byAlert: showFeature('show_filter_devices_by_alerts'),
        byGroups: showFeature('show_filter_by_device_groups'),
        byTags: showFeature("show_filter_by_tags_health_check"),
      },
    },
    users: {
      showBtnCreate: showFeature('show_btn_create_user'),
      showBtnChangeRole:  showFeature('show_btn_change_role'),
      showBtnSetCustomRole: showFeature('show_btn_custom_role'),
      showBtnSetAdminRole: showFeature('show_btn_set_admin_role'),
      showBtnSetMemberRole: showFeature('show_btn_set_member_role'),
      showBtnDeactivatedUser: showFeature('show_btn_deactivated_user'),
      showFilterSearch: showFeature('show_filter_search_users'),
    },
    userGroup: {
      showFilterSearch: showFeature('show_filter_user_group'),
      showBtnCreate: showFeature('show_btn_create_user_group'),
      showBtnSetDefault: showFeature('show_btn_set_default_user_group'),
      showBtnEdit: showFeature('show_btn_edit_user_group'),
    }
  };

  const requests: Requests = {
    // lastReading: showFeature("show_last_reading"),
    lastReading: true,
    getBignumberCountsDeviceList: showFeature('show_bignumbers_on_device_list'),
  };

  return {
    pages,
    features,
    requests,
  };
};
