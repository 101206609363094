import { useAuth } from '@group-link-one/gl-utils';
import {
  GetReportRow,
  ReportType,
  ReportTypeValues,
  useGLPagination,
  useI18n,
} from '@group-link-one/grouplink-components';
import { useQuery } from '@tanstack/react-query';

import { useReportListService } from '../../../Services/reportListService/useReportListService';
import { useMemo } from 'react';
import { useFBAnalytics } from '@/Context/FBAnalytics/FBAnalyticsProvider';
import { FBAnalyticsEventTitles } from '@/Context/FBAnalytics/types/FBAnalyticsTitles.types';

type ReportsAvailables = {
  name: string;
  type: ReportType;
  frontEndArgs: string[];
};

interface IUserReportsContent {
  reports: GetReportRow[] | undefined;
  reportsIsLoading: boolean;
  reportsRefetch: () => void;
  reportsAvailables: ReportsAvailables[] | undefined;
  isLoadingReportsAvailables: boolean;
  t: (key: string) => string;
}

type ReportAccess = {
  [key in ReportType]?: number[];
};

const reportAccess: ReportAccess = {
  UtilitiesHourlyExport: [279, 408],
  WaterDailyDeltaReport: [406],
  WaterReverseDeltaReport: [408],
  WaterDailyReport: [408, 406, 279],
};

export const useReportsContent = (): IUserReportsContent => {
  const { logEventAnalytics } = useFBAnalytics();
  const { user } = useAuth();
  const { getReports, getReportsAvailables } = useReportListService();
  const { state: paginationState } = useGLPagination();

  const { t } = useI18n();

  const reportSearch = useMemo(() => {
    logEventAnalytics({
      eventName: FBAnalyticsEventTitles.REPORT_LIST_SEARCH,
      eventDescription: 'Report List search',
      param1: paginationState.search,
    });

    return paginationState.search;
  }, [paginationState.search]);

  const {
    data: reports,
    isLoading: reportsIsLoading,
    refetch: reportsRefetch,
  } = useQuery({
    queryKey: ['get-reports'],
    queryFn: async () => {
      const response = await getReports({
        name: reportSearch,
      });

      return response;
    },
  });

  const { data: reportsAvailables, isLoading: isLoadingReportsAvailables } =
    useQuery({
      queryKey: ['reports-availables'],
      queryFn: async () => {
        const response = await getReportsAvailables();

        return response
          .filter((report) => {
            const allowedOrgs = reportAccess[report.type];

            if (!user) return;

            return allowedOrgs ? allowedOrgs.includes(user.org.id) : true;
          })
          .filter((report) =>
            Object.values(ReportTypeValues).includes(report.type),
          )
          .map((report) => ({
            ...report,
            name: t(
              `reports.modalStepper.stepper.one.form.reportType.${report.type}.name`,
            ),
          }));
      },
    });

  return {
    reports,
    reportsIsLoading,
    reportsRefetch,
    reportsAvailables,
    isLoadingReportsAvailables,
    t,
  };
};
