import { Libraries, useLoadScript } from '@react-google-maps/api';

import { env } from '../../env';

interface UseGoogleMapsService {
  googleMapsIsLoaded: boolean;
  getSuggestions: (
    search: string,
  ) => Promise<google.maps.places.AutocompletePrediction[]>;
  getPlaceDetails: (
    placeId: string,
  ) => Promise<google.maps.places.PlaceResult | undefined>;
}

const libraries: Libraries = ['places'];

export const useGoogleMapsService = (): UseGoogleMapsService => {
  const { isLoaded: googleMapsIsLoaded } = useLoadScript({
    googleMapsApiKey: env.VITE_REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries,
  });

  const getSuggestions = async (
    search: string,
  ): Promise<google.maps.places.AutocompletePrediction[]> => {
    if (!search || !googleMapsIsLoaded) {
      return [];
    }

    const autoCompleteService =
      new window.google.maps.places.AutocompleteService();

    const predictionsFiltered = await new Promise<
      google.maps.places.AutocompletePrediction[]
    >((resolve) => {
      autoCompleteService.getPlacePredictions(
        { input: search },
        (predictions, status) => {
          if (
            status === window.google.maps.places.PlacesServiceStatus.OK &&
            predictions
          ) {
            resolve(predictions);
          } else {
            resolve([]);
          }
        },
      );
    });

    return predictionsFiltered;
  };

  const getPlaceDetails = async (
    placeId: string,
  ): Promise<google.maps.places.PlaceResult | undefined> => {
    if (!googleMapsIsLoaded) {
      return;
    }

    const placesService = new window.google.maps.places.PlacesService(
      document.createElement('div'),
    );

    const placeDetails = await new Promise<google.maps.places.PlaceResult>(
      (resolve, reject) => {
        placesService.getDetails({ placeId }, (place, status) => {
          if (
            status === window.google.maps.places.PlacesServiceStatus.OK &&
            place
          ) {
            resolve(place);
          } else {
            reject(null);
          }
        });
      },
    );

    return placeDetails;
  };

  return {
    googleMapsIsLoaded,
    getSuggestions,
    getPlaceDetails,
  };
};
