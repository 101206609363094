import {
  GLSelectMultipleOptions,
  useI18n,
  useToast,
} from '@group-link-one/grouplink-components';
import { useEffect, useMemo, useState } from 'react';
import {
  FieldErrors,
  useForm,
  UseFormHandleSubmit,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTagsService } from '../../../../Services/tagsService/useTagsService';
import { useQuery } from '@tanstack/react-query';
import {
  TagSelected,
  useDetailsDeviceStore,
} from '../../store/details-device-store';
import { useFBAnalytics } from '@/Context/FBAnalytics/FBAnalyticsProvider';
import { FBAnalyticsEventTitles } from '@/Context/FBAnalytics/types/FBAnalyticsTitles.types';
import { useDeviceListAllDevices } from '../../Content/useDeviceListContent';
import { TagValueRow } from '@/Services/tagsService/useTagsService.type';
import { useExtractURLSearch } from '@/hooks/useExtractURLSearch';
import { useGLFlags } from '@group-link-one/gl-utils';

const schema = yup.object().shape({
  tag: yup.string().required('Tag is required'),
  tagValue: yup.string().when('tag', (tag, tagValueSchema) => {
    return tag
      ? tagValueSchema.required('Tag value is required')
      : tagValueSchema;
  }),
  preserve_existing_tags: yup.boolean().default(false),
});

type Data = {
  tagValue?: string;
  tag: string;
  preserve_existing_tags: boolean;
};

interface IUseModalAddTagContent {
  tagOptionsFormatted: GLSelectMultipleOptions[] | undefined;
  t: (key: string) => string;
  errors: FieldErrors<{
    tagValue?: string | undefined;
    tag: string;
  }>;
  tagSelected: TagSelected;
  tagValuesFormatted: GLSelectMultipleOptions[] | undefined;
  keepExistingTags: boolean;
  handleSubmit: UseFormHandleSubmit<
    {
      tagValue?: string | undefined;
      tag: string;
      preserve_existing_tags: boolean;
    },
    undefined
  >;
  onSubmit: (data: Data) => Promise<void | unknown>;
  setValue: UseFormSetValue<{
    tagValue?: string | undefined;
    tag: string;
    preserve_existing_tags: boolean;
  }>;
  onSelectTag: (selectedOptions: GLSelectMultipleOptions[]) => void;
  setKeepExistingTags: (value: boolean) => void;
  newTagValue: string;
  setNewTagValue: (value: string) => void;
  onSubmitTagValue: () => Promise<void | unknown>;
  tagInfos: TagValueRow | undefined;
}

export const useModalAddTagContent = (): IUseModalAddTagContent => {
  const { refetchDeviceList } = useDeviceListAllDevices();
  const { addToast } = useToast();
  const { logEventAnalytics } = useFBAnalytics();
  const { t } = useI18n();
  const { showFeature } = useGLFlags();
  const { getTagValue, assignDeviceToTag, getTags, createTagValue } =
    useTagsService();
  const { state: deviceState, actions: deviceActions } =
    useDetailsDeviceStore();

  const { setURLParams } = useExtractURLSearch();

  const [keepExistingTags, setKeepExistingTags] = useState<boolean>(false);
  const [newTagValue, setNewTagValue] = useState<string>('');

  const {
    handleSubmit,
    setValue,
    getValues,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    values: {
      tag: '',
      tagValue: '',
      preserve_existing_tags: false,
    },
  });

  const { data: tags } = useQuery({
    queryKey: ['get-tags', deviceState.modalAddTagIsOpen],
    queryFn: async () => {
      if (
        !showFeature('show_device_tags_page') &&
        !showFeature('show_tags_device_list')
      )
        return;
      const response = await getTags({});
      return response.rows;
    },
  });

  const { data: tagInfos, refetch } = useQuery({
    queryKey: ['get-tag-values', deviceState.tagSelected],
    queryFn: async () => {
      if (!deviceState.tagSelected.id) return;

      const response = await getTagValue({
        tag_key_id: Number(deviceState.tagSelected.id),
      });

      return response;
    },
  });

  function onSelectTag(selectedOptions: GLSelectMultipleOptions[]): void {
    deviceActions.setTagSelected({
      key: selectedOptions[0]?.text,
      id: selectedOptions[0]?.id,
      is_strict: tagInfos?.key.is_strict,
    });
    setValue('tag', selectedOptions[0]?.text);
  }

  const deviceTagAlreadyHaveValue = useMemo(() => {
    return deviceState.device?.tags?.find(
      (tag) => tag.key === deviceState.tagSelected.key,
    );
  }, [deviceState.device?.tags, deviceState.tagSelected]);

  function setDevice(tag: string, tagValue: string): void {
    deviceActions.setDevice({
      ...deviceState.device!,
      tags: [
        ...(deviceState.device?.tags || []),
        {
          key: tag,
          value: tagValue!,
        },
      ],
    });
  }

  async function onSubmit(data: Data): Promise<void | unknown> {
    if (deviceTagAlreadyHaveValue) {
      deviceActions.setModalSureToAddValueIsOpen(true);
      deviceActions.setDataToReassign(data);
      return;
    }

    try {
      await assignDeviceToTag({
        device_ids: [deviceState.device?.device_id!],
        operations: [
          {
            key: data.tag,
            value: data.tagValue!,
          },
        ],
        preserve_existing_tags: true,
        strict: false,
        is_value_unique: false,
      });
      logEventAnalytics({
        eventName: FBAnalyticsEventTitles.DEVICE_LIST_ADD_TAG,
        eventDescription: 'End Add Tag',
      });

      setDevice(data.tag, data.tagValue!);

      deviceActions.setTagSelected({
        key: '',
        value: '',
        id: '',
        is_strict: undefined,
      });

      setValue('tag', '');
      refetchDeviceList();
      reset({
        tag: '',
        tagValue: '',
      });

      deviceActions.setModalAddTagIsOpen(false);

      return;
    } catch (error) {
      addToast({
        title: t('common.error'),
        message: t(
          'deviceList.detailsModal.tags.modal.toast.errorWhenCreate.description',
        ),
        type: 'error',
      });
      return error;
    }
  }

  async function onSubmitTagValue(): Promise<void | unknown> {
    setValue('tagValue', newTagValue);

    const { tag: tagData, tagValue } = getValues();

    if (deviceState.tagSelected) {
      try {
        await createTagValue({
          tag_key_id: Number(deviceState.tagSelected.id),
          value: newTagValue,
        }).then(async () => {
          if (deviceTagAlreadyHaveValue) {
            deviceActions.setModalSureToAddValueIsOpen(true);
            deviceActions.setDataToReassign({
              tag: tagData,
              tagValue: tagValue!,
              preserve_existing_tags: false,
            });
            return;
          }

          onSubmit({
            tag: tagData,
            tagValue: tagValue!,
            preserve_existing_tags: false,
          });

          deviceActions.setModalAddTagIsOpen(false);
        });

        logEventAnalytics({
          eventName: FBAnalyticsEventTitles.TAGS_MANAGE_CREATE_VALUE,
          eventDescription: 'Tag value created',
        });
        refetch();

        refetchDeviceList();
      } catch (error) {
        addToast({
          title: t('common.error'),
          message: t(
            'deviceList.detailsModal.tags.modal.toast.errorWhenCreate.description',
          ),
          type: 'error',
        });
        return error;
      }
    }
  }

  const tagOptionsFormatted: GLSelectMultipleOptions[] | undefined =
    useMemo(() => {
      return (
        tags &&
        (tags.map((tag) => ({
          id: String(tag.id),
          text: tag.label,
        })) as GLSelectMultipleOptions[])
      );
    }, [tags]);

  const tagValuesFormatted: GLSelectMultipleOptions[] | undefined =
    useMemo(() => {
      return (
        tagInfos &&
        (tagInfos.values.map((tagValue) => ({
          id: String(tagValue.id),
          text: tagValue.value,
        })) as GLSelectMultipleOptions[])
      );
    }, [tagInfos]);

  useEffect(() => {
    if (deviceState.modalAddTagIsOpen) {
      setURLParams({
        q: '',
      });
    }
  }, [deviceState.modalAddTagIsOpen]);

  return {
    tagOptionsFormatted,
    t,
    errors,
    tagSelected: deviceState.tagSelected,
    tagValuesFormatted,
    keepExistingTags,
    newTagValue,
    tagInfos,
    handleSubmit,
    onSubmit,
    setValue,
    onSelectTag,
    setKeepExistingTags,
    setNewTagValue,
    onSubmitTagValue,
  };
};
