import {
  AuditRow,
  GLPaginationStoreActions,
  GLPaginationStoreState,
  IRequestParams,
  OnInfiniteScrollParams,
  TabProps,
  useAuditStore,
  useGLPagination,
  useI18n,
  useInfiniteScroll,
  useToast,
} from '@group-link-one/grouplink-components';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { format } from 'date-fns';
import { useEffect, useMemo } from 'react';

import { useAuditService } from '../../../Services/auditService/useAuditService';
import { AuditRequestOptions } from '../types/types';
import { useFBAnalytics } from '@/Context/FBAnalytics/FBAnalyticsProvider';
import { FBAnalyticsEventTitles } from '@/Context/FBAnalytics/types/FBAnalyticsTitles.types';
import * as Sentry from '@sentry/react';

export const AUDIT_LIST_IPP = 25;

interface IUseAuditContent {
  activeTab: TabProps | undefined;
  tabs: TabProps[];
  paginationState: GLPaginationStoreState;
  paginationActions: GLPaginationStoreActions;
  audits: AuditRow[] | undefined;
  auditIsLoading: boolean;
  isToShowAuditList: boolean;
  isToShowEmptyList: boolean;
  isToShowError: boolean;
  showFilteredEmptyList: boolean;
  getMoreDevicesOnScroll: () => Promise<void>;
  onInfiniteScroll: ({
    callback,
    event,
    scrollTrigger,
  }: OnInfiniteScrollParams) => Promise<void>;
}

export const useAuditContent = (): IUseAuditContent => {
  const { logEventAnalytics } = useFBAnalytics();
  const { state: paginationState, actions: paginationActions } =
    useGLPagination();
  const { t } = useI18n();
  const { addToast } = useToast();
  const { getAudits } = useAuditService();
  const { onInfiniteScroll } = useInfiniteScroll();
  const { state: auditState } = useAuditStore();
  const queryClient = useQueryClient();

  const activeTab = useMemo(() => {
    return paginationActions.getActiveTabById(paginationState.activeTabId);
  }, [paginationState.activeTabId]);

  const categoryFilter = useMemo(() => {
    logEventAnalytics({
      eventName: FBAnalyticsEventTitles.AUDIT_CATEGORY_FILTER,
      eventDescription: 'Audit Category Filter',
      param1: auditState.categoryFilter,
    });

    return auditState.categoryFilter;
  }, [auditState.categoryFilter]);

  const {
    data: audits,
    isLoading: auditIsLoading,
    isError: auditIsErrored,
  } = useQuery({
    queryKey: [
      'get-audits',
      categoryFilter || '',
      format(new Date(auditState.range.from!), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"),
      format(new Date(auditState.range.to!), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"),
    ],
    queryFn: async () => {
      try {
        const { nextPageToken, optionsToStoreNextPageToken } =
          paginationActions.getNextPageToken();

        const options: AuditRequestOptions = {
          ipp: AUDIT_LIST_IPP,
          next_page_token: nextPageToken,
          start_date: format(
            new Date(auditState.range.from!),
            "yyyy-MM-dd'T'HH:mm:ss.SSSxxx",
          ),
          end_date: format(
            new Date(auditState.range.to!),
            "yyyy-MM-dd'T'HH:mm:ss.SSSxxx",
          ),
        };

        if (categoryFilter?.length > 0) {
          options.category = categoryFilter;
          options.start_date = format(
            new Date(auditState.range.from!),
            "yyyy-MM-dd'T'HH:mm:ss.SSSxxx",
          );
          options.end_date = format(
            new Date(auditState.range.to!),
            "yyyy-MM-dd'T'HH:mm:ss.SSSxxx",
          );
        }

        const response = await getAudits(options);

        paginationActions.setNextPageToken({
          hasMore: response.has_more,
          nextPageToken: response.next_page_token || undefined,
          optionsToStoreNextPageToken,
        });

        if (
          response.rows.length === 0 &&
          categoryFilter.length > 0 &&
          auditState.range.from &&
          auditState.range.to
        ) {
          addToast({
            title: t('audit.list.emptyList.auditNotFound.title'),
            message: t('audit.list.emptyList.auditNotFound.description'),
            type: 'info',
          });
        }

        return response.rows;
      } catch (error) {
        Sentry.captureException(error);
      }
    },
  });

  async function getMoreDevicesOnScroll(): Promise<void> {
    const activeTabIdMap = {
      'tab-1': {
        do: async (options: IRequestParams) => {
          return await getAudits(options);
        },
        cachekey: 'get-audits',
      },
    };

    if (paginationState.isFetchingInfiniteScroll) {
      return;
    }

    const { nextPageToken, optionsToStoreNextPageToken } =
      paginationActions.getNextPageToken();
    if (!nextPageToken) {
      return;
    }

    const options: AuditRequestOptions = {
      ipp: AUDIT_LIST_IPP,
      next_page_token: nextPageToken,
      start_date: format(
        new Date(auditState.range.from || new Date()),
        "yyyy-MM-dd'T'HH:mm:ss.SSSxxx",
      ),
      end_date: format(
        new Date(auditState.range.to || new Date()),
        "yyyy-MM-dd'T'HH:mm:ss.SSSxxx",
      ),
    };

    if (categoryFilter.length > 0) {
      options.category = categoryFilter;
    }

    paginationActions.setIsFetchingInfiniteScroll(true);

    const tabMapActive =
      activeTabIdMap[`tab-${activeTab?.id}` as keyof typeof activeTabIdMap];

    const response = await tabMapActive.do(options);

    paginationActions.setIsFetchingInfiniteScroll(false);

    paginationActions.setNextPageToken({
      hasMore: response.has_more,
      nextPageToken: response.next_page_token || undefined,
      optionsToStoreNextPageToken,
    });

    const currentAuditList: AuditRow[] | undefined = queryClient.getQueryData([
      tabMapActive.cachekey,
      categoryFilter,
      format(
        new Date(auditState.range.from || new Date()),
        "yyyy-MM-dd'T'HH:mm:ss.SSSxxx",
      ),
      format(
        new Date(auditState.range.to || new Date()),
        "yyyy-MM-dd'T'HH:mm:ss.SSSxxx",
      ),
    ]);

    if (!currentAuditList) {
      return;
    }

    queryClient.setQueryData(
      [
        tabMapActive.cachekey,
        categoryFilter,
        format(
          new Date(auditState.range.from || new Date()),
          "yyyy-MM-dd'T'HH:mm:ss.SSSxxx",
        ),
        format(
          new Date(auditState.range.to || new Date()),
          "yyyy-MM-dd'T'HH:mm:ss.SSSxxx",
        ),
      ],
      [...currentAuditList, ...response.rows],
    );
  }

  function invalidateAllQueries(): void {
    queryClient.invalidateQueries({
      queryKey: ['get-audits'],
    });
  }

  const isToShowAuditList =
    !auditIsLoading &&
    !auditIsErrored &&
    audits &&
    Array.isArray(audits) &&
    audits.length > 0;

  const showFilteredEmptyList =
    !auditIsLoading &&
    !auditIsErrored &&
    Array.isArray(audits) &&
    audits.length === 0 &&
    categoryFilter?.length > 0;

  const isToShowEmptyList =
    audits && Array.isArray(audits) && audits.length === 0;

  const isToShowError = !auditIsLoading && auditIsErrored;

  useEffect(() => {
    paginationActions.tabsActions.setOnClick(paginationState.tabs[0], () => {
      paginationActions.setCurrentPage(0);
      paginationActions.setActiveTab(1);
    });

    invalidateAllQueries();
  }, []);

  useEffect(() => {
    paginationActions.setCurrentPage(0);
  }, [paginationState.search]);

  return {
    activeTab,
    tabs: paginationState.tabs,
    paginationState,
    paginationActions,
    audits,
    auditIsLoading,
    isToShowAuditList,
    isToShowEmptyList,
    isToShowError,
    showFilteredEmptyList,
    getMoreDevicesOnScroll,
    onInfiniteScroll,
  };
};
